<template>
  <v-container
    id="data-tables"
    class="fill-height align-start"
    fluid
    tag="section"
  >
    <v-container
      fluid
    >
      <ik-data-table
        :entity-name=" $t('TaxSetting')"
        :model="model"
        :use-delete-action="false"
        icon="mdi-cash-usd"
      >
        <template #footer>
          <v-toolbar
            color="white"
            flat
          />
        </template>
      </ik-data-table>
    </v-container>
  </v-container>
</template>

<script>

  import { IkDataTable } from 'metaflow-js'
  import Tax from '../../../model/export/Tax'

  export default {
    components: {
      IkDataTable,
    },
    data: function () {
      return ({
        model: Tax,
      })
    },
  }
</script>
